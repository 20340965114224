.paginate-container {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0px;
}

.paginate-link {
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.next-link {
  cursor: pointer;
}

.prev-link {
  cursor: pointer;
}

.pattern {
  background-color: rgb(243 243 243);
}
